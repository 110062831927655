:root {
  --red: #f93822;
  --lightred: #f85d4c;
  --darkred: #d22c19;
  --bottomBarHeight: 6vh;
  --actionButtonColor: var(--red);
  --actionButtonColor2: var(--darkred);
  --dialogColor: #222;
  --buttonShadowColor: #111;

  --swiper-pagination-bottom: auto;
  --swiper-pagination-top: 8px;
  --swiper-pagination-bullet-width: 16px;
  --swiper-pagination-bullet-height: 16px;
  --swiper-pagination-color: var(--red);
  --swiper-navigation-color: white;
}

a {
  text-decoration: none;
  color: black;
}

video {
  object-fit: cover;
}

#root {
  height: 100%;
}

ul, ol, li {
  text-align: left;
}

h1 {
  font-size: 1.2em;
  text-align: center;
}

.menuFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
}

.menuBoxes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
}

.menuBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: var(--actionButtonColor);
  box-shadow: var(--buttonShadowColor) 3px 3px;
  padding-bottom: 1em;
}

.menuBoxContainer h1 {
  font-weight: medium;
  color: black;
}

.menuBoxes .menuBox, .menuBoxes .emptyBox, .menuBoxes .grayBox {
  color: black;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.menuBoxes .menuBox {
  background-color: var(--actionButtonColor);
  box-shadow: var(--buttonShadowColor) 3px 3px;
}

.menuBoxes .emptyBox {
  height: 1em;
}

.menuBoxes .grayBox {
  color: black !important;
  background-color: #777 !important;
}

.menuBoxes .grayBox a {
  color: black !important;
}


.menuBoxes .menuBox a {
  color: black;
  text-transform: uppercase;
  text-decoration: none;
}

.menuBoxRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 1em;
}

.menuBoxRow .menuBox {
  background-color: var(--actionButtonColor2);
  box-shadow: var(--buttonShadowColor) 0px 0px;
  padding: 1em;
}

.sponsorInfo {
  font-size: 0.9em;
  text-align: center;
}

.BasicPage {
  font-size: 1.1em;
  color:white;
  font-weight: 400;
  background-color: var(--dialogColor);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BasicPage a {
  text-decoration: none;
  text-transform: uppercase;
  color: white;
}

.BasicPage .content a.newGameLink {
  color: var(--red) !important;
}

.BasicPage > div {
  margin: 1em;
}

.BasicPage .content div {
  margin-bottom: 1em;
  text-transform: uppercase;
}

.BasicPage .content a {
  color: white;
}

.BasicPage .content div.divider {
  background-color: var(--red);
  height: 0.3em;
  width: 100%;
}




.App {
  text-align: left;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0px;
  width: 100vw;
  height: var(--vh);
  overflow: hidden;
  user-select: none;
}

.fullscreen {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 2000;
}

.fullscreen img {
  height:100%;
  width:100%;
  object-fit:scale-down;
  object-position: "bottom"
}

.fullscreen video {
  height:100%;
  width:100%;
  object-fit:scale-down;
  object-position: center;
}

.fullscreen .ImageDescription {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background-color: black;
  color: white;
  opacity: 0.7;
}

.fullscreen .ImageDescription > div {
  padding: 1em;
}

.fullscreen .ImageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh);
  color: white;
  opacity: 0.9;
  text-align: left;
  font-size: 1.0em;
  line-height: 1.3em;
  font-weight: 400;
  text-shadow: black 0px 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fullscreen .ImageOverlay > div {
  padding: 1em;

}

.fullscreen .ImageOverlay a {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

#MapDiv {
  width: 100vw;
  height: var(--vh);
  background-color: black;
}

.InterfaceContainer {
  position: absolute;
  z-index: 1000;
  bottom: 0%;
  width: 100vw;
  /*height: var(--vh);*/
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
}



.GuidePanel, .ChatPanel, .InventoryPanel {
  /*background-color: yellow; */
  height: var(--vh);
  /* flex-grow: 1;*/
  display: flex;
  justify-content:flex-end;
  flex-direction: column;
}

.ModalPanel {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--vh);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9000;
  background-color: rgba(30,0,0,0.4);
}

.ModalDialogue {
  height: fit-content;
  width: 95%;
  background-color: #222;
  color: white;
  box-shadow: #111 3px 3px;
}

.ModalDialogue .Content {
  padding: 1em;
  text-align: center;
}

.ModalDialogue .textContent {
  text-align: left;
}

.RedButton {
  background-color: var(--red);
  padding: 1em;
  margin: 0.5em;
  display: inline-block;
  box-shadow: #111 3px 3px;
}

.GrayButton {
  background-color: #777;
  padding: 1em;
  margin: 0.5em;
  display: inline-block;
  box-shadow: #111 3px 3px;
}

.avatarChoiceItem {
  display: inline-block;
}

.GuidePointCover {
  background-color: black;
  color: white; 
  text-align: center;
  padding: 1em;
}

.GuidePointCover .PhotoList {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  gap: 1em;
}


.GuidePointCover img {
  width: 80%;
  margin: auto;
  max-height: 40vh;
  object-fit: scale-down;
}



.ChatTopBar {
  background-color: black;
  color: white; 
  text-align: left;
  padding: 0.5em;
}

.YouHaveAMessage {
  background-color: black;
  width: 100%;
  color: lightgray;
  padding: 0.5em;
  touch-action: pan-x pan-y;
  text-align: center;
}

.YouHaveAMessage a {
  color: white;
}

.GuideAudioPlayer {
  background-color: #323232;
  color: #fefefe; 
  text-align: left; 
  padding: 1em; 
}

.GuideAudioPlayer audio {
  display: block;
  background-color: #323232;
  margin: auto;
  width: 100%;
}

.ChatContainer {
  background-color: #323232;
  color: #fefefe; 
  text-align: left; 
  padding: 0.5em; 
  height: 63vh; 
  /*flex-grow: 1;*/
  overflow: scroll; 
  overscroll-behavior-y: contain; 
 /* scroll-snap-type: y proximity; */

  display: flex;
  /* justify-content: end; */
  flex-direction: column;
  gap: 1em;
}

.ChatMessage {
  display: flex;
  flex-direction: row;
  padding-top: 1em;
}

.ChatMessageAvatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  min-width: 50px;
  background-color: red;
  border-radius: 50%;
  overflow:hidden;
}

.ChatMessageAvatar img {
  width: 50px;
}

.ChatMessageContent {
  display: inline-block;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em; 
  flex-grow: 4;
}

.ChatMessageContent .imgContainer {
  display: inline-block;
  width: 100%;
  height: 50vw;
  overflow: hidden;
  border: 1px solid black;
  margin-top: 1em;
}

.ChatMessageContent img{
  display: block;
  width: 100%;
  position: relative;
  bottom: 200%;
  object-fit:scale-down;
 /* object-position: bottom;
  
  clip-path: inset(50% 0px 0px 0px);*/
}

.InMessageButton {
  background-color: #222;
  padding: 1em;
  border: 1px solid black;
  margin-bottom: 1em;
  box-shadow: #111 6px 6px;
}

.action {
  background-color: #444 !important;
}

/* .GuideBottomBar do usuniecia, w przewodniku jest zastapiony przez BottomBar, sprawdzic czy wszedzie */
.GuideBottomBar {
  
  height: 7vh;
  background-color: var(--red);
  display: flex;

  align-items: center;
  align-content: center;

  flex-direction: row;
  justify-content: space-between;
  gap: 1em;

  padding-left: 1em;
  padding-right: 1em;

  touch-action: pan-x pan-y;
  color: white;
  font-size: x-large;
}

.GuideBottomBar > a {
  display: inline-block;
}

.GuideBottomBar img {
  height: 5vh;
}


.InventoryContent {

  width:100%;
  background-color: black;
  color: #fefefe; 
  text-align: left; 
  padding: 0.5em; 
  height: 50vh; 
  overflow: scroll; 
  overscroll-behavior-y: contain; 
  scroll-snap-type: y proximity;

}

.InventoryContent .description {
  opacity: 0.8;
  padding: 1em;
  text-align: center;
  line-height: 1.5em;
}

.InventoryContent h2 {
  text-align: center;
  font-weight: 400;
  font-size: 1.5em;
}

.InventoryContent .InventoryItems {
  width: fit-content;
}

.InventoryContent .InventoryItem {
  display: inline-block;
  width: 29vw;
  height: 29vw;
  overflow:hidden;
  text-align: center;
  vertical-align:middle;
  margin: 0.2em;
}

.InventoryContent .circle {
  border-radius: 50%;
  background-color: #222;
}
.InventoryItem img {
  width: 90%;
}

.BottomBar {
  width: 100%;
  height: 7vh;
  background-color: var(--red);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  touch-action: pan-x pan-y;
}

.BottomBar > .items div {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.BottomBar > *:first-child {
  margin-left: 0.5em;
}

.BottomBar > *:last-child {
  margin-right: 0.5em;
}

.BottomBar a {
  display: inline;
}
.BottomBar img.logo {
  height: 5vh !important;
  width: auto !important;
}

.bottomTitle {
  font-size: x-large;
  color: white;
}

.BottomBarItem {
  width: 5vh;
  height: 5vh;
  display: inline-block;
  background-color: black;
  overflow:hidden;

  text-align: center;
  vertical-align:middle;
  line-height: 5vh;
  font-size: 3vh;
  color: black;
}

.circle {
  border-radius: 50%;
}

.BottomBarItem img {
  opacity: 1;
  width: 5vh;
}

img.completed {
  opacity: 0.8;
  filter: blur(2px);
}

.regularIconVisited img {
  filter: grayscale(1)
}

.userOnMapIcon {
  width: 25px !important;
  height: 25px !important;
  background-color: red;
  border-radius: 50%;
}

.photoIcon, .photoIconVisited {
  width: 30px !important;
  height: 30px !important;
  background-color: gray;
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  opacity: 0.6;
}

.photoIcon img, .photoIconVisited img {
  width: 20px;
  height: 20px;
  margin: auto;
  filter: invert(0);
  opacity: 0.8;
}

.photoIconVisited {
  background-color: black !important;
}
.photoIconVisited img {
  filter: invert(1) !important;
}

.MapPopup {
  display: flex;
  gap: 0.5em;
  font-size: 1em !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.MapPopup img {
  object-fit:scale-down;
  width: 50px;
  display: block;
}

.MapPopup div  {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
}

.leaflet-popup-content-wrapper {
  background-color: rgba(0,0,0,0.8) !important;
  border-radius: 3px;
  box-shadow: none;
}
.leaflet-popup-content {
  margin: 10px !important;
}
