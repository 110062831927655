:root {
  --vh: 100%;
 }

html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  height: var(--vh);
}

body {
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow: hidden;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.leaflet-layer img.leaflet-tile-loaded {filter: invert(100%) saturate(10%) contrast(80%); opacity: 0.9 !important; mix-blend-mode: multiply !important;}
div.leaflet-control-attribution {display: none;}
div.leaflet-popup-content-wrapper {background-color: black; color: white;}
div.leaflet-popup-tip {background-color: black;}